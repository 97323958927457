import React, { useState, useEffect } from "react";

async function ForceUpdateMLBLines(pwd) {

    try {
        return fetch("http://api.bspete.com/GetAndStoreMLBLines?pwd=" + pwd, {
            method: "GET"
        }).catch((e) => console.log(e));
    }
    catch (e){
        return (500, "error ocurred");
    }
}

async function ForceUpdateNFLLines(pwd) {

    try {
        return fetch("http://api.bspete.com/GetAndStoreNFLLines?pwd=" + pwd, {
            method: "GET"
        }).catch((e) => console.log(e));
    }
    catch (e) {
        return (500, "error ocurred");
    }

}

function GetFormattedResult(responseText, status, isLoading) {

    if (isLoading) {
        return <span style={{ color: '#ffff00' }} >Loading</span>
    }
    if (status == "200") {
        return <span style={{ color: '#66ff00' }} >{responseText}</span>
    }
    else {
        return <span style={{ color: '#ff0000' }} >{responseText}</span>
    }
}

function Admin(){

    const [pass, setPass] = useState();
    const [isRunningFunction, setIsRunningFunction] = useState();
    const [functionResult, setFunctionResult] = useState();
    const [functionStatus, setFunctionStatus] = useState();
    const [functionResultDisplay, setFunctionResultDisplay] = useState();

    useEffect(() => {

        if ((functionResult == undefined) && !isRunningFunction) {
            setFunctionResultDisplay(<br />);
        }
        else {
            setFunctionResultDisplay(GetFormattedResult(functionResult, functionStatus, isRunningFunction))
        }

    }, [functionResult, functionStatus, isRunningFunction]);

    var adminPage = <div>
        <div className="App-header-top">
            <h1>Admin Functions</h1>
        </div>
        <br/>
        <div>
            <label>
                Enter admin password: <input type="text" name="passwordInput" onInput={e => setPass(e.target.value)} />
            </label>
        </div>
        <br />
        <div>{functionResultDisplay}</div>
        <br />
        <div>
            <button disabled={isRunningFunction} className="App-button" onClick={() => {
                setIsRunningFunction(true);
                var responseContainer;
                ForceUpdateMLBLines(pass)
                    .then(response => {
                        responseContainer = response;
                        return response.text();
                        }
                    )
                    .then(data => {
                        setFunctionResult(data)
                        setFunctionStatus(responseContainer.status);
                    })
                    .then(() => setIsRunningFunction(false))
                    .catch(() => setIsRunningFunction(false));
            }}>Force Update MLB Lines</button>
        </div>
        <div>
            <button disabled={isRunningFunction} className="App-button" onClick={() => {
                setIsRunningFunction(true);
                var responseContainer;
                ForceUpdateNFLLines(pass)
                    .then(response => {
                        responseContainer = response;
                        return response.text();
                    }
                    )
                    .then(data => {
                        setFunctionResult(data)
                        setFunctionStatus(responseContainer.status);
                    })
                    .then(() => setIsRunningFunction(false))
                    .catch(() => setIsRunningFunction(false));
            }}>Force Update NFL Lines</button>
        </div>
    </div>

    return adminPage;
}

export default Admin
