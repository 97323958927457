import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link} from "react-router-dom";
import './App.css';
import Home from "./pages/Home"
import Admin from "./pages/Admin"
import MLBLineMatches from "./pages/MLB/MLBLineMatches";
import MLBLineMismatches from "./pages/MLB/MLBLineMismatches";
import MLBLanding from "./pages/MLB/MLBLanding";
import NFLLanding from "./pages/NFL/NFLLanding";
import NFLLineMatches from "./pages/NFL/NFLLineMatches";
import NFLLineMismatches from "./pages/NFL/NFLLineMismatches";

function App() {
    return (
    <div className="App">
                <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="mlb" element={<MLBLanding />} />
                    <Route path="mlb/matches" element={<MLBLineMatches />} />
                    <Route path="mlb/mismatches" element={<MLBLineMismatches />} />
                    <Route path="nfl" element={<NFLLanding />} />
                    <Route path="nfl/matches" element={<NFLLineMatches />} />
                    <Route path="nfl/mismatches" element={<NFLLineMismatches />} />
                    <Route path="admin" element={<Admin />} />
                </Routes>
            </BrowserRouter>
    </div>
  );
}

export default App;
