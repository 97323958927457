import React from "react";
import { useNavigate } from "react-router-dom";

function MLBLanding() {

    const navigate = useNavigate()

    const goToMatches = () => {
        navigate("/mlb/matches");
    }

    const goToMismatches = () => {
        navigate("/mlb/mismatches");
    }

    var mlbLandingPage = <div>

        <div className="App-header-center">
            <h1 >MLB</h1>
            <div>
                <button className="App-button" onClick={() => goToMatches()}>Matches</button>
                <button className="App-button" onClick={() => goToMismatches()}>Mismatches</button>
            </div>
        </div>
    </div>


    return mlbLandingPage;
}

export default MLBLanding