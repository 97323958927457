import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";

function signStringToSymbol(signString) {

    if (signString === "Minus") {
        return "-";
    }
    else if (signString === "Plus") {
        return "+";
    }

    return undefined;
}

function formatLineAsDiv(mismatch) {

    var oSymbolColor = '#66ff00';
    var uSymbolColor = '#FF0000';

    var baseOverOddsString;
    var baseUnderOddsString;

    if (!mismatch.BaseLine.HasMultiplierOdds) {
        var boOddsSign = signStringToSymbol(mismatch.BaseLine.OverOdds.Sign);
        var boOddsValue = mismatch.BaseLine.OverOdds.Value;
        var buOddsSign = signStringToSymbol(mismatch.BaseLine.UnderOdds.Sign);
        var buOddsValue = mismatch.BaseLine.UnderOdds.Value;

        baseOverOddsString = boOddsSign + "" + boOddsValue;
        baseUnderOddsString = buOddsSign + "" + buOddsValue;
    }
    else {
        baseOverOddsString = mismatch.BaseLine.OverMultiplier + "x";
        baseUnderOddsString = mismatch.BaseLine.UnderMultiplier + "x";
    }


    var compLines = mismatch.ComparativeLines.map((compLine) => {

        if (!compLine.HasMultiplierOdds) {
            var coOddsSign = signStringToSymbol(compLine.OverOdds.Sign);
            var coOddsValue = compLine.OverOdds.Value;
            var cuOddsSign = signStringToSymbol(compLine.UnderOdds.Sign);
            var cuOddsValue = compLine.UnderOdds.Value;

            return <><div className="App-item-small">{compLine.Provider} : {compLine.Value} | <span style={{ color: oSymbolColor }} >O</span> {coOddsSign}{coOddsValue} | <span style={{ color: uSymbolColor }} >U</span> {cuOddsSign}{cuOddsValue}</div><br /></>
        }
        else
        {
            return <><div className="App-item-small">{compLine.Provider} : {compLine.Value} | <span style={{ color: oSymbolColor }} >O</span> {compLine.OverMultiplier}x | <span style={{ color: uSymbolColor }} >U</span> {compLine.UnderMultiplier}x</div><br /></>
        }
        
    })

    return <div>
        <div>{mismatch.BaseLine.PlayerName} - {mismatch.BaseLine.StatType}</div>
        <div className="App-item-small">{mismatch.BaseLine.Provider} : {mismatch.BaseLine.Value} | <span style={{ color: oSymbolColor }} >O</span> {baseOverOddsString} | <span style={{ color: uSymbolColor }} >U</span> {baseUnderOddsString}</div><br />
        {compLines}<br />
    </div>

}

function GetNFLLineData(baseProvider) {

    return fetch("http://api.bspete.com/GetStoredNFLLineMismatches?baseProvider="+baseProvider, {
        method: "GET"
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        }).catch((e) => console.log(e));

}

function NFLLineMismatches() {

    const [lineData, setLineData] = useState(null);
    const [baseProvider, setBaseProvider] = useState("PP");

    useEffect(() => {

        GetNFLLineData(baseProvider).then(response => setLineData(response));

        const timeoutID = setInterval(() => {
            GetNFLLineData(baseProvider).then(response => setLineData(response));
        }, 30000);

        return () => clearInterval(timeoutID);

    }, [baseProvider]);

    var retrievalDate = undefined;
    var updatedString = undefined;

    if (lineData != undefined) {

        var mismatches = lineData.LineMismatches;

        var lineElements = mismatches?.map(formatLineAsDiv);

        lineElements = <div>{lineElements}</div>

        if (lineData.RetrievalTime != undefined) {
            retrievalDate = Date.parse(lineData.RetrievalTime);
            updatedString = "NFL Mismatches updated on " + dateFormat(retrievalDate, "mmmm dS, h:MM:ss TT");
        }

    }

    if (lineData == undefined) {
        return <div>
            <h2>Loading...</h2>
        </div>
    }

    return (
        <div>
            <div>
                <h2>{updatedString}</h2>
            </div>
            <br />
            <div>
                <label>
                    Base Provider&nbsp;
                    <select className="App-item" name="baseProviders" id="baseProvidersSelect" onChange={e => setBaseProvider(e.target.value)}>
                        <option value="PP" key="1" defaultValue>PrizePicks</option>
                        <option value="UD" key="2">Underdog</option>
                    </select>
                </label>
            </div>
            <br />
            <div>{lineElements}</div>
        </div>
    );

}

export default NFLLineMismatches