import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Home() {

    const navigate = useNavigate()

    const goToMLB = () => {
        navigate("/mlb");
    }

    const goToNFL = () => {
        navigate("/nfl");
    }

    const goToAdmin = () => {
        navigate("/admin");
    }

    var homePage = <div>
        
        <div className="App-header-center">
            <h1 >Lookie Bookie</h1>
            <div>
                <button className="App-button" onClick={() => goToNFL()}>NFL</button>
                <button className="App-button" onClick={() => goToMLB()}>MLB</button>
                <button className="App-button" onClick={() => goToAdmin()}>Admin</button>
            </div>
            </div>
        </div>


    return homePage;
}

export default Home