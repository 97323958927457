import React, { useState, useEffect } from "react";
import dateFormat, { masks } from "dateformat";

function signStringToSymbol(signString) {

    if (signString === "Minus") {
        return "-";
    }
    else if (signString === "Plus") {
        return "+";
    }

    return undefined;
}

function formatLineAsDiv(line) {
    var oddsSymbol = "X";
    var oddsSign = "X";
    var oddsValue = "X";
    var symbolColor = 'white';

    if (line.OverOdds != null) {
        oddsSymbol = "O";
        oddsSign = signStringToSymbol(line.OverOdds.Sign);
        oddsValue = line.OverOdds.Value;
        symbolColor = '#66ff00';
    }
    else if (line.UnderOdds != null) {
        oddsSymbol = "U";
        oddsSign = signStringToSymbol(line.UnderOdds.Sign);
        oddsValue = line.UnderOdds.Value;
        symbolColor = '#FF0000';
    }

    return <div>{line.PlayerName} | <span style={{ color: symbolColor }} >{oddsSymbol}</span> {line.Value} {line.StatType} {oddsSign}{oddsValue} | {line.PlayerTeam} vs {line.OpponentTeam} | {line.Provider}</div>
}

function GetNFLLineData(baseProvider) {

    return fetch("http://api.bspete.com/GetStoredNFLLineMatches?baseProvider=" + baseProvider, {
        method: "GET"
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        }).catch((e) => console.log(e));

}

function NFLLineMatches() {

    const [lineData, setLineData] = useState(null);
    const [filterString, setFilterString] = useState("");
    const [baseProvider, setBaseProvider] = useState("PP");
    

    useEffect(() => {

        GetNFLLineData(baseProvider).then(response => setLineData(response));

        const timeoutID = setInterval(() => {
            GetNFLLineData(baseProvider).then(response => setLineData(response));
        }, 30000);

        return () => clearInterval(timeoutID);

    }, [baseProvider]);

    var retrievalDate = undefined;
    var updatedString = undefined;

    if (lineData != undefined) {

        var lines = lineData.MatchedLines;

        var filteredLines = lines.filter(line => line.PlayerName) //make sure not undefined or null

        filteredLines = filteredLines.filter(line => line.PlayerName.toLowerCase().includes(filterString.toLowerCase().trim()))

        var lineElements = filteredLines?.map(formatLineAsDiv);

        lineElements = <div>{lineElements}</div>

        if (lineData.RetrievalTime != undefined) {
            retrievalDate = Date.parse(lineData.RetrievalTime);
            updatedString = "NFL Matches updated on " + dateFormat(retrievalDate, "mmmm dS, h:MM:ss TT");
        }
        else {
            updatedString = "Loading..."
        }

    }

    if (lineData == undefined) {
        return <div>
            <h2>Loading...</h2>
        </div>
    }

    return (
        <div>
            <div>
                <h2>{updatedString}</h2>
            </div>
            <div>
                <label>
                    Base Provider&nbsp;
                    <select className="App-item" name="baseProviders" id="baseProvidersSelect" onChange={e => setBaseProvider(e.target.value)}>
                        <option value="PP" key="1" defaultValue>PrizePicks</option>
                        <option value="UD" key="2">Underdog</option>
                    </select>
                </label>
            </div>
            <br />
            <div>
                <label>
                    Name Filter <input type="text" name="filterInput" onChange={e => setFilterString(e.target.value)} />
                </label>
            </div>
            <br />
            {lineElements}
        </div>
    );
}

export default NFLLineMatches