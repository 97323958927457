import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";

function signStringToSymbol(signString) {

    if (signString === "Minus") {
        return "-";
    }
    else if (signString === "Plus") {
        return "+";
    }

    return undefined;
}

function formatLineAsDiv(mismatch) {

    var oSymbolColor = '#66ff00';
    var uSymbolColor = '#FF0000';

    var boOddsSign = signStringToSymbol(mismatch.BaseLine.OverOdds.Sign);
    var boOddsValue = mismatch.BaseLine.OverOdds.Value;
    var buOddsSign = signStringToSymbol(mismatch.BaseLine.UnderOdds.Sign);
    var buOddsValue = mismatch.BaseLine.UnderOdds.Value;

    var compLines = mismatch.ComparativeLines.map((compLine) => {

        var coOddsSign = signStringToSymbol(compLine.OverOdds.Sign);
        var coOddsValue = compLine.OverOdds.Value;
        var cuOddsSign = signStringToSymbol(compLine.UnderOdds.Sign);
        var cuOddsValue = compLine.UnderOdds.Value;

        return <><div className="App-item-small">{compLine.Provider} : {compLine.Value} | <span style={{ color: oSymbolColor }} >O</span> {coOddsSign}{coOddsValue} | <span style={{ color: uSymbolColor }} >U</span> {cuOddsSign}{cuOddsValue}</div><br /></>
    })

    return <div>
        <div>{mismatch.BaseLine.PlayerName} - {mismatch.BaseLine.StatType}</div>
        <div className="App-item-small">{mismatch.BaseLine.Provider} : {mismatch.BaseLine.Value} | <span style={{ color: oSymbolColor }} >O</span> {boOddsSign}{boOddsValue} | <span style={{ color: uSymbolColor }} >U</span> {buOddsSign}{buOddsValue}</div><br />
        {compLines}
    </div>

}

function GetMLBLineData() {

    return fetch("http://api.bspete.com/GetStoredMLBLineMismatches", {
        method: "GET"
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        }).catch((e) => console.log(e));

}

function MLBLineMismatches() {

    const [lineData, setLineData] = useState(null);

    useEffect(() => {

        GetMLBLineData().then(response => setLineData(response));

        const id = setInterval(() => {
            GetMLBLineData().then(response => setLineData(response));
        }, 30000);

        return () => clearInterval(id);

    }, []);

    var retrievalDate = undefined;
    var updatedString = undefined;

    if (lineData != undefined) {

        var mismatches = lineData.LineMismatches;

        var lineElements = mismatches?.map(formatLineAsDiv);

        lineElements = <div>{lineElements}</div>

        if (lineData.RetrievalTime != undefined) {
            retrievalDate = Date.parse(lineData.RetrievalTime);
            updatedString = "MLB Mismatches updated on " + dateFormat(retrievalDate, "mmmm dS, h:MM:ss TT");
        }

    }

    if (lineData == undefined) {
        return <div>
            <h2>Loading...</h2>
        </div>
    }

    return (
        <div>
            <div>
                <h2>{updatedString}</h2>
            </div>
            <br />
            <div>{lineElements}</div>
        </div>
    );

}

export default MLBLineMismatches